import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import moment from 'moment-timezone';

import './ListTimeItem.css';

function ListTimeItem(props){
    const [time, setTime] = useState('');
    const [locationFormatted, setLocationFormatted] = useState('');

    useEffect(() => {
        var l = props.zone.split('/');
        setLocationFormatted(`${l[1]}, ${l[0]}`);

        setTime(moment.utc(new Date(Date.now())).tz(props.zone).format('h:mm a'));

        function updateTime(){
            //moment.utc(new Date(Date.now())).tz('Australia/Brisbane').format()
            var newTime = moment.utc(new Date(Date.now())).tz(props.zone).format('h:mm a');
            setTime(newTime);
        }

        setInterval(updateTime, 1000 * 10);
    }, [props.zone]);


    return (
        <div className="ListTimeItem">
            <p><span className="location">{locationFormatted.replace('_',' ')}</span><span className='time'>{time}</span></p>
        </div>
    )
}

ListTimeItem.propTypes = {
    zone : PropTypes.string
}

export default ListTimeItem;
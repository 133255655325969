import React, { useCallback, useEffect, useState } from "react";
import { SocialIcon } from "react-social-icons";
import moment from "moment-timezone";
import ReactGA from "react-ga";

import "./App.css";

import ListTimeItem from "./components/ListTimeItem";

// Any timezones in here will be excluded, this is usually ambigous ones are they don't format nicely.
const excludeTZ = [
	"Antarctica/DumontDUrville",
	"Australia/LHI",
	"Etc/GMT-14",
	"HST",
	"Etc/GMT-13",
	"NZ-CHAT",
	"Universal",
	"W-SU",
	"WET",
	"Zulu",
	"ROC",
	"ROK",
	"NZ",
	"PRC",
	"PST8PDT",
	"MST7MDT",
	"MST",
	"MET",
	"GMT+0",
	"GMT-0",
	"GMT",
	"GB-Eire",
	"GB",
	"CST6CDT",
	"CST",
];

// Google Analytics initialization
ReactGA.initialize("UA-202653905-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
	const [theme, setTheme] = useState("light");
	const [zones, setZones] = useState([]);
	const [noZones, setNoZones] = useState(true);

	const [mainTime, setMainTime] = useState("");
	const [mainTZ, setMainTZ] = useState("Australia/Brisbane");
	const [location, setLocation] = useState("Brisbane, Australia");

	const mainProcess = useCallback(() => {
		findZones().then((data) => {
			// Sort in ascending order
			if (data.length === 0) {
				setNoZones(true);
				return;
			} else {
				setNoZones(false);
			}

			data.sort((a, b) => {
				var aTime = moment(Date.now()).tz(a);
				var bTime = moment(Date.now()).tz(b);
				return (
					aTime.hours() * 3600 +
					aTime.minutes() * 60 +
					aTime.seconds() -
					(bTime.hours() * 3600 +
						bTime.minutes() * 60 +
						bTime.seconds())
				);
			});

			setMainTZ(data[0]);
			var curTime = moment.utc(new Date(Date.now())).tz(data[0]);
			if (curTime) {
				setMainTime(curTime.format("h:mm a"));
			} else {
				setMainTime("00:00 pm");
			}

			// Format as State, Country
			var l = data[0].split("/");

			setLocation(`${l[1]}, ${l[0]}`);
			setZones([...data.slice(1, 6)]);
		});
	}, []);

	useEffect(() => {
		document.documentElement.setAttribute(
			"data-theme",
			localStorage.getItem("theme") || "light"
		);
		setTheme(localStorage.getItem("theme") || "light");

		mainProcess();

		// Update the clock every 10 seconds
		function updateTime() {
			var newTime = moment.utc(new Date(Date.now())).tz(mainTZ);
			if (newTime) setMainTime(newTime.format("hh:mm a" || "00:00 pm"));
		}

		setInterval(updateTime, 1000 * 10);

		// Get a new array every 30mins.
		setInterval(mainProcess, 1000 * 1800);
	}, [mainTZ, mainProcess]);

	// Finds all zones past 5pm
	function findZones() {
		return new Promise((resolve) => {
			// Find what offset we would need to get to 5pm in any timezone
			var tempZones = [];
			var i = 0;

			moment.tz.names().forEach((tz) => {
				i++;
				var tzHours = moment.tz(tz).hours();
				if (tzHours >= 17) {
					if (
						!excludeTZ.includes(tz) &&
						!tz.includes("Etc") &&
						!tz.includes("GMT+") &&
						!tz.includes("GMT-")
					) {
						tempZones.push(tz);
					}
				}

				if (i === moment.tz.names().length) {
					return resolve(tempZones);
				}
			});
		});
	}

	function switchTheme() {
		document.documentElement.classList.add("theme-changing");
		if (theme === "dark") {
			document.documentElement.setAttribute("data-theme", "light");
			setTheme("light");
			localStorage.setItem("theme", "light");
			ReactGA.event({
				category: "User",
				action: "Set Theme: Light",
			});
		} else {
			document.documentElement.setAttribute("data-theme", "dark");
			setTheme("dark");
			localStorage.setItem("theme", "dark");
			ReactGA.event({
				category: "User",
				action: "Set Theme: Dark",
			});
		}
		window.setTimeout(function () {
			document.documentElement.classList.remove("theme-changing");
		}, 1000);
	}
	return (
		<div className="App">
			<div className="content">
				{!noZones ? (
					<div>
						<p style={{ textAlign: "center" }}>
							It&apos;s 5pm somewhere in the world!
						</p>
						<br></br>
						<br></br>
						<p>
							It is currently{" "}
							<span className="main-time">{mainTime}</span> in:
						</p>
						<h1>{location.replace("_", " ")}</h1>
						<hr />
						{zones.map((zone) => {
							return <ListTimeItem zone={zone} key={zone} />;
						})}
					</div>
				) : (
					<p>
						Oh No, there are currently no timezones after 5pm and
						before 7pm. Please allow some time and we will continue
						searching in the background.
					</p>
				)}
			</div>
			{theme === "light" ? (
				<svg
					onClick={switchTheme}
					className="svg-button"
					xmlns="http://www.w3.org/2000/svg"
					enableBackground="new 0 0 24 24"
					height="24px"
					viewBox="0 0 24 24"
					width="24px"
					fill="#ffffff"
				>
					<rect fill="none" height="24" width="24" />
					<path d="M9.37,5.51C9.19,6.15,9.1,6.82,9.1,7.5c0,4.08,3.32,7.4,7.4,7.4c0.68,0,1.35-0.09,1.99-0.27C17.45,17.19,14.93,19,12,19 c-3.86,0-7-3.14-7-7C5,9.07,6.81,6.55,9.37,5.51z M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36 c-0.98,1.37-2.58,2.26-4.4,2.26c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z" />
				</svg>
			) : (
				<svg
					onClick={switchTheme}
					className="svg-button"
					xmlns="http://www.w3.org/2000/svg"
					enableBackground="new 0 0 24 24"
					height="24px"
					viewBox="0 0 24 24"
					width="24px"
					fill="#000000"
				>
					<rect fill="none" height="24" width="24" />
					<path d="M12,9c1.65,0,3,1.35,3,3s-1.35,3-3,3s-3-1.35-3-3S10.35,9,12,9 M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5 S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1 s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0 c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95 c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41 L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41 s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06 c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z" />
				</svg>
			)}
			<span className="owner">
				<SocialIcon
					url="https://www.facebook.com/michaelk2101"
					style={{ height: 25, width: 25, marginRight: "0.5rem" }}
				/>
				<SocialIcon
					url="https://github.com/michaelk2101"
					style={{ height: 25, width: 25, marginRight: "0.5rem" }}
					bgColor={theme === "dark" ? "#FFF" : null}
				/>
				<SocialIcon
					url="https://www.linkedin.com/in/michael-kajewski-055827156"
					style={{ height: 25, width: 25, marginRight: "0.5rem" }}
				/>
				<a
					href="https://github.com/michaelk2101"
					target="_blank"
					rel="noreferrer"
				>
					&copy; Michael {moment(Date.now()).year()}
				</a>
			</span>
		</div>
	);
}

export default App;
